.map-container {
  height: 20rem;
  min-height: 100%;
}

.container {
  background-color: white;
}

.contact {
  background-color: var(--dark-black);
  color: white;
}

#contact a,
#contact a:hover,
#contact a:visited {
  color: var(--butterfly-blue);
}

@media only screen and (max-width: 500px) {
  #easteregg {
    display: none;
  }
}