:root {
  color-scheme: light dark;
  --butterfly-blue: #46a8fb;
  --gradient-light-blue: #21d4fd;
  --gradient-dark-blue: #1280de;
  --dark-black: #191919;
  --transparent-black: #0000004d;
  --transparent-white: #ffffff4d;
}

.App {
  text-align: center;
}

@media only screen and (max-width: 500px) {
  .card-1 {
    margin-top: 0px !important;
  }
}

.container-fluid {
  padding: 0px;
}

footer {
  background-color: var(--dark-black);
  color: white;
  text-align: center;
  line-height: 50px;
  height: 50px;

}

footer>p {
  margin: 0px;
}

.notfound {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 50px #0000001f;
  color: var(--dark-black);
  padding: 2em;
}