#bigcard {
  background-color: var(--gradient-light-blue);
  background-image: linear-gradient(19deg, var(--gradient-light-blue) 0%, var(--gradient-dark-blue) 100%);
}

#MugShot {
  width: 60%;
  display: block;
  margin: 20px auto;
  border-radius: 500px;
}

#ResumeBar {
  height: 100%;
  padding: 20px;
  color: white;
}

#ResumeBar h1 {
  text-align: center;
}

#ResumeBar>div {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

#ResumeBody {
  background: white;
  padding: 2em;
}

#ResumeBody a,
#ResumeBody a:hover,
#ResumeBody a:visited {
  color: var(--butterfly-blue);
}

#ResumeBar a,
#ResumeBar a:hover,
#ResumeBar a:visited {
  color: white;
}

#subHead {
  background: white;
  color: var(--butterfly-blue);
  padding: 10px;
  border-radius: 1000px;
  text-align: center;
}

#lastP {
  display: inline-block;
}

.card-1 {
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: 0 1px 50px #0000001f;
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  overflow: hidden;
}

th {
  display: none;
}

td {
  padding: 0.5em;
}

.wave-line {
  --size: 5px;
  --b: 1px;
  --m: 1;
  --p: calc(var(--m)*var(--size));
  --R: calc(var(--size)*sqrt(var(--m)*var(--m) + 1) + var(--b)/2);
  --_g: #0000 calc(99% - var(--b)), #000 calc(101% - var(--b)) 99%, #0000 101%;
  -webkit-mask:
    radial-gradient(var(--R) at left 50% bottom calc(-1*var(--p)), var(--_g)) calc(50% - 2*var(--size)) calc(50% - var(--size)/2 - var(--b)/2)/calc(4*var(--size)) calc(var(--size) + var(--b)) repeat-x,
    radial-gradient(var(--R) at left 50% top calc(-1*var(--p)), var(--_g)) 50% calc(50% + var(--size)/2 + var(--b)/2)/calc(4*var(--size)) calc(var(--size) + var(--b)) repeat-x;
  background: white;
  height: 6px;
  margin: 1em 0;
}

@media only screen and (max-width: 500px) {
  .interests {
    display: none;
  }
}