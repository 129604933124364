h1 {
  font-family: "Gilroy-Extrabold", sans-serif;
}

h2 {
  font-size: xx-large;
}

.about-section {
  background-color: var(--butterfly-blue);
  color: white;
}

.resume-section {
  position: relative;
  top: -30vh;
  display: hidden;
}

@media only screen and (max-width: 500px) {
  .resume-section {
    top: calc(-20vh - 1em) !important;
  }
}

@media (prefers-color-scheme: dark) {
  .big-banner {
    background-color: var(--dark-black);
  }
}

.big-banner {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
