.intro-card {
    position: relative;

    @media (prefers-color-scheme: dark) {
        color: white !important;
    
        a {
            color: white !important;
        }
    
        .pill {
            border-color: var(--transparent-white) !important;
        }

        h2 {
            color: var(--transparent-white) !important;
        }
    }

    color: var(--dark-black);
    margin: 0 3rem;

    display: flex;
    flex-direction: column;
    gap: 24px;

    a {
        text-decoration: none;
        color: var(--dark-black);
    }
    

    a #rocket {
        position: relative;
        left: 0px;
        top: 0px;
        transition: left ease 2s, top ease 2s;
    }

    a:hover #rocket {
        top: -400px;
        left: 400px;
    }

    .intro-card-title {
        @media only screen and (max-width: 500px) {
            flex-direction: column-reverse;
            gap: 1rem !important;

            .rocket {
                display: none;
            }
        }

        display: flex;
        align-items: baseline;
        justify-content: space-between;
        gap: 5rem;
    }

    h1 span {
        font-family: "Gilroy-Extrabold", sans-serif;
    }

    h1 {
        font-family: sans-serif;
        font-size: 24px;
        font-weight: 600;
        margin: 0;
    }

    h2 {
        font-weight: 600;
        font-size: 24px;
        margin: 0;
        color: var(--transparent-black);
    }

    .content {
        filter: saturate(0);
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        div {
            display: flex;
            gap: 0.5rem;

            p {
                font-size: 1rem;
                margin: 0;
            }
        }

    }

    .title-img {
        height: 80px;
        width: 80px;
    }

    #CardThumb {
        border-radius: 1000px;
    }

    .pill {
        font-size: 1rem;
        border-radius: 1000px;
        padding: 4px 10px;
        border: 1px solid var(--transparent-black);
        cursor: pointer;
    }

    .noborder {
        border: 0px;
    }

    .misc {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .misc-left {
        display: flex;
        align-items: center;
        gap: 11px;
    }
}